* {
  box-sizing: border-box !important;
}

html, p, input {
  font-family: 'Roboto', sans-serif;
}

.appContDiv{
    display: flex;
    justify-content: left;
    align-items: top;
    background-color: white;
    flex-direction: row;
    width:100%;
    height:auto;
  }

.homeDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(
      100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0)
    );
    background-color: white;
    flex-direction: column;
    width:100%;
    flex:1;
    flex-grow:1;
  }
  
  @supports (-webkit-touch-callout: none) {
    .homeDiv {
      /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */
      height: -webkit-fill-available;
    }
  }
  
  .message {
    padding: 30px; 
    word-wrap: break-word;
    border-bottom: 1px solid rgba(0,0,0,.1);
    display: flex;
  justify-content: flex-start; /* Add this line */
  width: 100%; /* Add this line */
  box-sizing: border-box; /* Add this line */ 
  }
  
  .message.user {
    background-color: white;
    color: black;
    align-self: flex-start;
    display:block;
  }
  
  .message.bot {
    background-color: #f5f5f5;
    color: black;
    align-self: flex-start;
    display:block;
}

.input-form {
display: flex;
padding: 10px;
/*background-color: #f5f5f5;*/
border-top: 1px solid #eee;
}

input {
flex-grow: 1;
padding: 5px;
border: none;
border-radius: 5px;
outline: none;
box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
}

button {
/*background-color: #007bff; */
color: white;
border: none;
/*border-radius: 5px;*/
padding: 10px 20px;
/*margin-left: 10px;*/
cursor: pointer;
transition: background-color 0.3s;
}

button:hover {
/*background-color: #0056b3;*/
}
  

html,
body {
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
}

.chat-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  /*padding: 20px;*/
}
.messages {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0px;
  overflow-y: auto;
}
.input-form {
  margin-top: auto;
}



body {
  margin: 0;
  padding: 0;
}

header {
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  /* G3 blue is rgb(10, 41, 114) or #0B2972 ... old color here was #04112F */
  background-color: #0B2972;
  color: #fff;
  padding: 15px 20px 20px 20px;
  z-index:100;
  width:100%;
}

.header-visible {
display:block;
}

main {
  /* padding-top: 120px; /* this should be greater than the header height */
  /* padding-bottom: 150px; /* this should be equal to the footer height */
  /* min-height: 100vh; /* this is important to ensure the content fills the full height of the viewport */
  width:100%;
  /*flex:1 0;*/
  overflow-y:auto;
  /* Add this CSS rule to set the min-height for your main content container */
  flex-grow: 1;
  /*  max-height: calc(100vh - [header-height] - [footer-height]);*/

}

footer {
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  color: #fff;
  padding: 10px;
  background-color:white;
  box-sizing:border-box;
  width:100%;
}



.message-content {
  display: flex;
  width: 100%; /* Add this line */
}

.avatar-div {
  width: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height:100%;
}

.content-div {
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%; /* Add this line */
  overflow-x:auto;
}

.loading-message {
  margin-left: 30px;
  margin-top:30px;
  font-style: italic;
  color: #999;
}

body, html {
  overflow: hidden;
}

.message-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  resize: none;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  overflow: auto;
  max-height: calc(24px * 9); /* 24px is the line height, 9 lines max */
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
  margin-left:10px;
  color:black;
}

.chat-container .content-div p{
  margin-top:3px;
  margin-bottom:3px;
  line-height:1.5rem;
}


.chat-container li{
  margin-top:3px;
  margin-bottom:3px;
}


/* Styles for landscape mode */
@media (max-width: 767px) {



  header{
    display:block;
    padding:0px !important;
  }


  .sidebarHeader img {
    height: 70px !important;
  }
}


/* Sidebar css */
/* Add this rule for the home container when the sidebar is visible */
.homeDiv.sidebar-visible {
  /*overflow-x: hidden;

  max-width: 100vw;*/
}

.sidebar {

  /* G3 blue is rgb(10, 41, 114) or #0B2972 ... old color here was #04112F */
  background-color: #0B2972;
  display: flex;
  left: 0;
  top: 80px; /* Start below header */
  bottom: 0;
color:white;
  
  overflow-y: auto;
  z-index: 200;

  transition: width 0.3s;

  width:0px;
  overflow: hidden;
  white-space: nowrap;

  display:flex;
  flex-direction: column;
  box-sizing:border-box;
  flex-shrink: 0; /* Prevent shrinking */
  height:calc(
    100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0)
  );
}

.sidebarHeader img {
  height: 70px;
}

.sidebar.visible {
  width:260px;
}

.sidebar.hidden {
  display:none;
}

.sidebar ul {
  list-style-type: none;

}

.sidebar li {
  margin-bottom: 1rem;
}

.sidebar-toggle {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 300;
  color:white;
  margin-right:10px;
  box-sizing:border-box;
  padding-left:10px;
}



/* FOOTER STYLES */
.footerStyle{
  font-size: .6rem ;
  color: black;
  text-align: center;
}

.footerPriceStyle {
  text-align: center;
  font-size: .6rem;
  color: red;
}

@media (min-width: 768px) {
.footerStyle{
  font-size: .9rem !important;
  color: black !important;
  text-align: center !important;
}

.footerPriceStyle {
  text-align: center;
  font-size: .9rem;
  color: red;
}

  .sidebar {
    width: 260px;
  }

  .sidebar.hidden {
    display:flex;
  }

  .homeDiv{
    width:calc(100vw - 260px);
  }

  header{
    display:none !important;
  }

  .sidebar-toggle {
    
  }
}

.sidebarHeader{
  width:100%;
  height:50px;
  margin:10px;
}
.addChatBtn{

  border: 1px solid hsla(0,0%,100%,.2);
  background-color:transparent;
  color: white;
  width:auto;
  font-size:1rem;
  margin:10px 10px 10px 10px;
  height:50px;
  text-align:left;
  border-radius:10px;

  align-items: center; /* Add this line to vertically center the icon and text */

  font-size: 1rem; /* Increase the font size */


}

.addChatBtn:hover{
  /*background-color:hsla(240,9%,59%,.1);*/
  --tw-bg-opacity: 1;
  background-color: rgba(9, 33, 91,var(--tw-bg-opacity));
}

.plus-icon {
  margin-right: 8px; /* Add some space between the icon and the text */
  font-size: 1rem; /* Increase the font size */
  
}

a.messageTopic{
  -webkit-text-size-adjust: 100%;
font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
tab-size: 4;
--color-primary: #19c37d;
--color-secondary: #715fde;
--color-error: #ef4146;
--gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
--text-primary: #202123;
--text-default: #353740;
--text-secondary: #6e6e80;
--text-disabled: #acacbe;
--text-error: var(--color-error);
color-scheme: light;
--tw-bg-opacity: 1;
font-size: .875rem;
line-height: 1.25rem;
--tw-text-opacity: 1;
border: 0 solid #d9d9e3;
box-sizing: border-box;
--tw-border-spacing-x: 0;
--tw-border-spacing-y: 0;
--tw-translate-x: 0;
--tw-translate-y: 0;
--tw-rotate: 0;
--tw-skew-x: 0;
--tw-skew-y: 0;
--tw-scale-x: 1;
--tw-scale-y: 1;
--tw-pan-x: ;
--tw-pan-y: ;
--tw-pinch-zoom: ;
--tw-scroll-snap-strictness: proximity;
--tw-ordinal: ;
--tw-slashed-zero: ;
--tw-numeric-figure: ;
--tw-numeric-spacing: ;
--tw-numeric-fraction: ;
--tw-ring-inset: ;
--tw-ring-offset-width: 0px;
--tw-ring-offset-color: #fff;
--tw-ring-color: rgba(59,130,246,.5);
--tw-ring-offset-shadow: 0 0 transparent;
--tw-ring-shadow: 0 0 transparent;
--tw-shadow: 0 0 transparent;
--tw-shadow-colored: 0 0 transparent;
--tw-blur: ;
--tw-brightness: ;
--tw-contrast: ;
--tw-grayscale: ;
--tw-hue-rotate: ;
--tw-invert: ;
--tw-saturate: ;
--tw-sepia: ;
--tw-drop-shadow: ;
--tw-backdrop-blur: ;
--tw-backdrop-brightness: ;
--tw-backdrop-contrast: ;
--tw-backdrop-grayscale: ;
--tw-backdrop-hue-rotate: ;
--tw-backdrop-invert: ;
--tw-backdrop-opacity: ;
--tw-backdrop-saturate: ;
--tw-backdrop-sepia: ;
color: inherit;
text-decoration: inherit;
position: relative;
display: flex;
cursor: pointer;
align-items: center;
gap: .75rem;
word-break: break-all;
border-radius: .375rem;
padding-left: .75rem;
padding-right: .75rem;
padding-bottom: .75rem;
padding-top: .75rem;

}

a.messageTopic:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(9, 33, 91,var(--tw-bg-opacity));
  /*padding-right:1rem;*/
}

.topicSelected{
  background-color: rgba(41, 108, 224,var(--tw-bg-opacity));
}

.messageTopicContent{
  -webkit-text-size-adjust: 100%;
  font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  tab-size: 4;
  --color-primary: #19c37d;
  --color-secondary: #715fde;
  --color-error: #ef4146;
  --gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
  --text-primary: #202123;
  --text-default: #353740;
  --text-secondary: #6e6e80;
  --text-disabled: #acacbe;
  --text-error: var(--color-error);
  color-scheme: light;
  --tw-bg-opacity: 1;
  font-size: 1rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: inherit;
  cursor: pointer;
  border: 0 solid #d9d9e3;
  box-sizing: border-box;
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59,130,246,.5);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  position: relative;
  max-height: 1.25rem;
  flex: 1 1 0%;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}


.messageCopyFade{
  -webkit-text-size-adjust: 100%;
  font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  tab-size: 4;
  --color-primary: #19c37d;
  --color-secondary: #715fde;
  --color-error: #ef4146;
  --gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
  --text-primary: #202123;
  --text-default: #353740;
  --text-secondary: #6e6e80;
  --text-disabled: #acacbe;
  --text-error: var(--color-error);
  color-scheme: light;
  --tw-bg-opacity: 1;
  font-size: 1rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: inherit;
  cursor: pointer;
  word-break: break-all;
  border: 0 solid #d9d9e3;
  box-sizing: border-box;
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59,130,246,.5);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 10;
  width: 2rem;
  background-image: linear-gradient(to left,var(--tw-gradient-stops));
  --tw-gradient-from: #202123;
  --tw-gradient-to: rgba(55, 65, 81, 0);
  --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
}

.messageTopics{
  -webkit-text-size-adjust: 100%;
  font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  tab-size: 4;
  --color-primary: #19c37d;
  --color-secondary: #715fde;
  --color-error: #ef4146;
  --gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
  --text-primary: #202123;
  --text-default: #0abb94;
  --text-secondary: #6e6e80;
  --text-disabled: #acacbe;
  --text-error: var(--color-error);
  color-scheme: light;
  line-height: inherit;
  --tw-bg-opacity: 1;
  border: 0 solid #d9d9e3;
  box-sizing: border-box;
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59,130,246,.5);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  
  flex: 1 1 0%;
  flex-direction: column;
  overflow-y: auto;
  padding:.375rem;
}

.messageTopic svg{
  width:1.25rem;
  height:1.25rem;

  
}


.help-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, auto);
  gap: 10px;
  margin-left: 50px;
  margin-right: 50px;
  overflow-y:visible;
  box-sizing:border-box;
  
}

.help-bubble {
  background-color: rgba(217,217,227,.2);
  border-radius: 5px;
  padding: 10px;
  font-size:.9rem;
}

.help-bubble-dalle {
  background-color: rgba(217,217,227,.2);
  border-radius: 5px;
  padding: 10px;
  font-size:.9rem;
  display:none;
}

.modelDescriptionSpan {
  font-size:.9rem;
  font-style: italic;
}

.help-bubble-heading {
  border-radius: 5px;
  padding: 10px;
   text-align: center;
   font-size:1rem;
}
.helpIcon{
  clear:both;
  display:block;
  text-align:center;
  margin: 0 auto;
  margin-bottom:10px;
}

.help-header{
  text-align:center;
  font-size:2rem;
  grid-column: 1 / span 3;
  padding-bottom:20px;
  padding-top:20px;
}

a.kbLink{
  font-size:1rem;
  color:#353740;
}

a.kbLink:visited{
  font-size:1rem;
  color:#353740;
}

.topicLeftButton{
  position:absolute;
  top:12px;
  right:30px;
  cursor:pointer;
}

.topicRightButton{
  position:absolute;
  top:12px;
  right:10px;
  cursor:pointer;
}

.topicDiv{
  position:relative;
}

.messageTopicContent input{
  width: 100px;
  border-radius:0px;
}

.topicSelected .messageTopicContent{
  max-width:110px;
}

@media (max-width: 480px) {
  .help-bubble, .help-bubble-heading {
    /*display:none;*/
    
  }
  header{
    padding:0px;
  }
  .sidebarHeader img {
    height: 70px !important;
  }

  .help-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, auto);
    gap: 10px;
    margin-left: 10px;
    margin-right: 10px;
    
  }
}


.scrollButton {
  position: fixed;
  bottom: 120px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  font-size: 1.5rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}


/* CODE BLOCKS */
.codeHeader{
  color: rgb(248, 248, 242); 
  background-color: rgb(40, 42, 54);
  padding:5px;
  border-top-left-radius:5px !important;
  border-top-right-radius:5px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left:20px;

}
.codeLanguage{
  color:white;
  font-weight: bold;

}
.copyCodeBtn{
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size:.8em;
}

pre.codeBox{
  margin-top:0px !important;
  border-top-left-radius:0px !important;
  border-top-right-radius:0px !important;
  border-top:0px;
  background-color:rgba(0,0,0,1) !important;
  max-width: 100%;
}
.hljs-bullet, .hljs-link, .hljs-selector-id, .hljs-symbol, .hljs-title{
  color:#f22c3d !important;
}

.copyCodeBtn svg {
  margin-right: 4px;
}

.codeBlockContainer {
  max-width: 100%;
  overflow-x: auto;
}


.message-content {
  display: flex;
}

.content-div {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  overflow-wrap: break-word;
}

/*
pre{
  padding:20px;
  border-radius:20px;
}
code,
.code,
pre {
  background: rgba(32,33,35,1);
  color: white;
  font-size: 16px;
}

code :before,
.code :before,
pre :before {
  display: block;
  width: calc(100%);
  margin-left: -3px;
  margin-top: -3px;
  padding: 3px;
  text-transform: uppercase;
  content: attr(data-lang);
  background: rgba(32,33,35,1);
}*/

.activeMic{
  background-color:red !important;
}

.dalleImage{
  border: 1px solid lightgray;
  border-radius: 20px;
  width: 512px;
  padding: 20px;
}

.DALLEContainer{
  display:flex;
  gap:12px
}


.markdown {
  max-width:none
}

.markdown h1, .markdown h2 {
  font-weight:600
}

.markdown h2 {
  margin-bottom: 1rem;
  margin-top:2rem
}

.markdown h3 {
  font-weight:600
}

.markdown h3, .markdown h4 {
  margin-bottom: .5rem;
  margin-top:1rem
}

.markdown h4 {
  font-weight:400
}

.markdown h5 {
  font-weight:600
}

.markdown blockquote {
  --tw-border-opacity: 1;
  border-color: rgba(142, 142, 160, var(--tw-border-opacity));
  border-left-width: 2px;
  line-height: 1.5rem;
  margin: 0;
  padding-bottom: .5rem;
  padding-left: 1rem;
  padding-top:.5rem
}

.markdown blockquote > p {
  margin:0
}

.markdown blockquote > p:after, .markdown blockquote > p:before {
  display:none
}

.markdown ol {
  counter-reset: list-number;
  display: flex;
  flex-direction: column;
  /*list-style-type: none;*/
  /*padding-left:0*/
}

.markdown ol > li {
  counter-increment: list-number;
  /*display: block;*/
  margin-bottom: 0;
  margin-top: 0;
  min-height:28px
}

/*
.markdown ol > li:before {
  --tw-translate-x: -100%;
  --tw-text-opacity: 1;
  color: rgba(142, 142, 160, var(--tw-text-opacity));
  content: counters(list-number, ".") ".";
  padding-right: .5rem;
  position: absolute;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}*/

:is(.dark .markdown ol > li):before {
  --tw-text-opacity: 1;
  color:rgba(172, 172, 190, var(--tw-text-opacity))
}

.markdown ul {
  display: flex;
  flex-direction: column;
  /*padding-left:0*/
}

.markdown ul > li {
  /*display: block;*/
  margin: 0;
  min-height:28px
}
/*
.markdown ul > li:before {
  --tw-translate-x: -100%;
  content: "•";
  font-size: .875rem;
  line-height: 1.25rem;
  padding-right: .5rem;
  position: absolute;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}*/

.markdown ol li > p, .markdown ol li > pre, .markdown ul li > p, .markdown ul li > pre {
  margin:0
}

.markdown ol ol, .markdown ol ul, .markdown ul ol, .markdown ul ul {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top:0
}

.markdown table {
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-collapse: collapse;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.markdown th {
  background-color: rgba(236, 236, 241, 1);
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-width: 1px;
  padding:.25rem .75rem;
  text-align:left;
}

.markdown th:first-child {
  border-top-left-radius:.375rem
}

.markdown th:last-child {
  border-right-width: 1px;
  border-top-right-radius:.375rem
}

.markdown td {
  border-bottom-width: 1px;
  border-left-width: 1px;
  padding:.25rem .75rem;
  text-align:left;
}

.markdown td:last-child {
  border-right-width:1px
}

.markdown tbody tr:last-child td:first-child {
  border-bottom-left-radius:.375rem
}

.markdown tbody tr:last-child td:last-child {
  border-bottom-right-radius:.375rem
}

.markdown a {
  text-decoration-line: underline;
  text-underline-offset:2px
}


.continue-button{
  width:50%;
  margin: 0 auto;
  border-radius:20px;
  margin-top:10px;
  border-color:rgb(39, 39, 39);
  color:rgb(39, 39, 39);
}

/* The Modal (background) */
.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  padding-top: 150px;
  padding-left: 50px;
  transform: translate(-50%, -50%);
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 99999; /* Sit on top */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: fit-content;
  height: fit-content;
  overflow: hidden;
}

/* Modal Content */
.modal-content-buttons {
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-top: 20px;
  gap: 20px;
}

/* Modal Buttons */
.modal-button {
  display: none; /* Hidden by default */
  /* G3 blue is rgb(10, 41, 114) or #0B2972 ... old color here was #04112F */
  background-color: #0B2972;
  border: none;
  color: white;
  /*text-align: center;*/
  font-weight: bold;
  font-size: 17px;
  border-radius: 6px;
  /*width: fit-content;*/
  /*position: relative;*/
  /*alignment: center;*/
  /*margin-left: auto;*/
  /*margin-right: auto;*/
}

.modal-button:hover:enabled,.modal-button:focus:enabled {
  background-color: rgb(9, 33, 91);
  cursor: pointer;
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}

.linear-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 104px;
  height: 200px;
  position: relative;
  overflow: hidden;
}
.inter-draw{
  background: #FFF;
  width: 100%;
  height: 100px;
  position: absolute;
  top: 100px;
}
.inter-right--top{
  background: #FFF;
  width: 100%;
  height: 20px;
  position: absolute;
  top: 20px;
  left: 100px;
}
.inter-right--middle{
  background: #FFF;
  width: 100%;
  height: 20px;
  position: absolute;
  top: 20px;
  left: 100px;
}
.inter-right--bottom{
  background: #FFF;
  width: 100%;
  height: 20px;
  position: absolute;
  top: 60px;
  left: 100px;
}
.inter-crop{
  background: #FFF;
  width: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 100px;
}

 {
  color: #000000;
}


@media (prefers-color-scheme: dark) {
  .dark\:bg-g3-blue {
    --tw-bg-opacity: 1;
    background-color: rgb(11 41 114 / var(--tw-bg-opacity));
  }
}