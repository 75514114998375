/* Override Dracula theme styles */
.code-container {
    background-color: #f6f8fa;
    color: #24292e;
    border-radius: 6px;
    padding: 16px;
  }
  
  /* Override specific styles for tokens or classes */
  .code-container .token {
    font-style: normal;
  }
  
  .code-container .token.comment,
  .code-container .token.prolog,
  .code-container .token.doctype,
  .code-container .token.cdata {
    color: #6a737d;
  }
  
  .code-container .token.punctuation {
    color: #24292e;
  }
  
  .code-container .token.property,
  .code-container .token.tag,
  .code-container .token.boolean,
  .code-container .token.number,
  .code-container .token.constant,
  .code-container .token.symbol,
  .code-container .token.deleted {
    color: #22863a;
  }
  
  .code-container .token.selector,
  .code-container .token.attr-name,
  .code-container .token.string,
  .code-container .token.char,
  .code-container .token.builtin,
  .code-container .token.inserted {
    color: #032f62;
  }
  
  .code-container .token.operator,
  .code-container .token.entity,
  .code-container .token.url,
  .code-container .language-css .token.string,
  .code-container .style .token.string {
    color: #d73a49;
  }
  
  .code-container .token.atrule,
  .code-container .token.attr-value,
  .code-container .token.keyword {
    color: #6f42c1;
  }
  
  .code-container .token.function,
  .code-container .token.class-name {
    color: #005cc5;
  }
  
  .code-container .token.regex,
  .code-container .token.important,
  .code-container .token.variable {
    color: #e90;
  }
  
  .code-container .token.important,
  .code-container .token.bold {
    font-weight: bold;
  }
  
  .code-container .token.italic {
    font-style: italic;
  }
  