.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #071330;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.code-block {
  background-color: #f6f8fa;
  border-radius: 5px;
  padding: 10px;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
  overflow-x: auto;
  max-width: 100%;
}

.code-block-inline {
  background-color: #f6f8fa;
  border-radius: 3px;
  padding: 2px 5px;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
}



.model-selection {  
  display: none !important;  
  /* Add any additional styles as needed */  
}  
  
.model-selection.visible {  
  display: flex !important;  
}  
  
.model-name {  
  display: none !important;  
  text-align: center;  
  /* Add any additional styles as needed */  
}  
  
.model-name.visible {  
  display: flex !important;  
}  
